import { GetServerSidePropsContext, GetStaticPropsContext } from 'next'
import { ParsedUrlQuery } from 'querystring'

import { defaultLocale, Locale } from './language'

export interface PageUrlOptions {
  newLocale?: Locale
  locale?: Locale
  parameters?: Record<string, string>
}

export enum PageType {
  PAGE = 'page',
  ERROR_PAGE = 'errorPage',
  HOME_PAGE = 'homePage',
  SHOP_PAGE = 'shopPage',
  CART_PAGE = 'cartPage',
  SIGNUP_PAGE = 'signupPage',
  LOGIN_PAGE = 'loginPage',
  LOGOUT_PAGE = 'logoutPage',
  PASSWORD_RECOVERY_PAGE = 'passwordRecoveryPage',
  ACCOUNT_PAGE = 'accountPage',
  ACCOUNT_ADDRESS_PAGE = 'accountAddressPage',
  COLLECTION = 'collection',
  PRODUCT = 'product',
  BLOG_PAGE = 'blogPage',
  BLOG_POST = 'blogPost',
  BLOG_CATEGORY = 'blogCategory',
  BLOG_AUTHOR = 'blogAuthor',
  WORK_PAGE = 'workPage',
  WORK = 'work',
  SERVICE_PAGE = 'servicePage',
  SERVICE = 'service',
}

export interface CustomStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale: Locale
  locales: Locale[]
}

export interface CustomErrorStaticPropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetStaticPropsContext<Q> {
  locale?: Locale
  locales: Locale[]
}

export interface CustomServerSidePropsContext<
  Q extends ParsedUrlQuery = ParsedUrlQuery
> extends GetServerSidePropsContext<Q> {
  locale: Locale
}

export interface StaticPathWithSlug {
  params: {
    slug: string
  }
  locale: Locale
}

export interface StaticPathWithSlugs {
  params: {
    slugs: string[]
  }
  locale: Locale
}

export type PageTypeValues<T> = {
  [K in PageType]: T
}

const pageRoutes: PageTypeValues<string[]> = {
  [PageType.HOME_PAGE]: [],
  [PageType.ERROR_PAGE]: ['page-that-does-not-exist-EqchyqRX3HXNjTvx'],
  [PageType.SHOP_PAGE]: ['shop'],
  [PageType.CART_PAGE]: ['cart'],
  [PageType.PAGE]: ['[page_slug]'],
  [PageType.SIGNUP_PAGE]: ['signup'],
  [PageType.LOGIN_PAGE]: ['login'],
  [PageType.LOGOUT_PAGE]: ['api', 'auth', 'logout'],
  [PageType.PASSWORD_RECOVERY_PAGE]: ['password-recovery'],
  [PageType.ACCOUNT_PAGE]: ['account'],
  [PageType.ACCOUNT_ADDRESS_PAGE]: ['account', 'addresses'],
  [PageType.COLLECTION]: ['shop', '[collection_slug]'],
  [PageType.PRODUCT]: ['products', '[product_slug]'],
  [PageType.BLOG_PAGE]: ['blog'],
  [PageType.BLOG_POST]: ['blog', '[post_slug]'],
  [PageType.BLOG_CATEGORY]: ['blog', 'category', '[category_slug]'],
  [PageType.BLOG_AUTHOR]: ['blog', 'author', '[author_slug]'],
  [PageType.WORK_PAGE]: ['work'],
  [PageType.WORK]: ['work', '[work_slug]'],
  [PageType.SERVICE_PAGE]: ['what-we-do'],
  [PageType.SERVICE]: ['what-we-do', '[service_slug]'],
}

/**
 * Gets relative page URL.
 */
export const getPageUrl = (
  pageType: PageType,
  slugs: string | string[] | null = null,
  options: PageUrlOptions = {}
) => {
  // Get URL locale prefix
  const localePrefix =
    options?.newLocale &&
    options.newLocale !== options?.locale &&
    options.newLocale !== defaultLocale
      ? `${options.newLocale}`
      : null

  // Get relative URL
  const urlSlugs = slugs && Array.isArray(slugs) ? slugs : [slugs]
  let slugIndex = 0
  const urlSegments =
    pageRoutes[pageType]?.map((segment) => {
      // Replace segment placeholder with a slug or an empty string
      if (/\[.*\]/.test(segment)) {
        slugIndex += 1
        return urlSlugs?.[slugIndex - 1] ?? ''
      }

      return segment
    }) ?? []
  const url = [localePrefix, ...urlSegments].filter(Boolean).join('/')

  // Get URL parameters
  const searchParams = new URLSearchParams()
  Object.entries(options?.parameters ?? {}).forEach((parameter) => {
    if (parameter[0] && parameter[1]) {
      searchParams.set(parameter[0], parameter[1])
    }
  })
  const parameters = searchParams.toString()

  const urlWithParameters = [url, parameters].filter(Boolean).join('?')
  return `/${urlWithParameters}`
}

const collectionPageTypes = [PageType.SHOP_PAGE, PageType.COLLECTION]

/**
 * Determines if page type has product collection.
 */
export const isRouteCollection = (pageType: PageType): boolean =>
  collectionPageTypes.includes(pageType)
