import {
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useContext,
} from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classnames'

import { SanityLink } from '@data/sanity/queries/types/content'
import { HubSpotFormUrl } from '@data/sanity/queries/types/link'
import { useLogoutUser } from '@lib/auth'
import { getPageUrl, isRouteCollection, PageType } from '@lib/routes'
import { SiteContext } from '@lib/site'

import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'
import { FormValueContext } from './form'

interface LinkProps {
  link: SanityLink
  onClick?: () => void
  children?: ReactNode
  showCollectionCount?: boolean
  buttonVariant?: ButtonVariant
  buttonSize?: ButtonSize
  buttonColor?: ButtonColor
}

type ExternalLinkProps = Pick<
  SanityLink,
  'id' | 'url' | 'fontCase' | 'buttonStyles' | 'isButton'
> &
  Pick<
    LinkProps,
    'buttonVariant' | 'buttonSize' | 'buttonColor' | 'children'
  > & {
    ariaLabel?: string
    tabIndex?: number
    className?: string
  }

const ExternalLink = ({
  id,
  url,
  ariaLabel,
  tabIndex,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyles,
  isButton,
  fontCase,
  className,
  children,
}: ExternalLinkProps) => {
  return (
    <ButtonLink
      id={id}
      href={url}
      target={url && !url.match('^mailto:') ? '_blank' : '_self'}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      rel="noopener noreferrer"
      variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
      size={buttonSize ?? getButtonSize(buttonStyles?.size)}
      color={buttonColor ?? getButtonColor(buttonStyles?.color)}
      icon={buttonStyles?.icon}
      iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
      className={cx(
        { btn: isButton, 'w-full': buttonStyles?.isFullWidth },
        fontCase ?? '',
        className
      )}
    >
      {children}
    </ButtonLink>
  )
}

type HubSpotLinkProps = Pick<
  SanityLink,
  'id' | 'fontCase' | 'buttonStyles' | 'isButton'
> &
  Pick<
    LinkProps,
    'buttonVariant' | 'buttonSize' | 'buttonColor' | 'children'
  > & {
    hubSpotFormUrl: HubSpotFormUrl
    ariaLabel?: string
    tabIndex?: number
    className?: string
  }

const HubSpotLink = ({
  id,
  hubSpotFormUrl,
  ariaLabel,
  tabIndex,
  buttonVariant,
  buttonSize,
  buttonColor,
  buttonStyles,
  isButton,
  fontCase,
  className,
  children,
}: HubSpotLinkProps) => {
  const { formValues } = useContext(FormValueContext)

  // Add dynamic URL parameters using form values
  const searchParams = new URLSearchParams()
  hubSpotFormUrl.dynamicParameters?.forEach(
    ({ source, hubSpotInternalName }) => {
      if (formValues && source in formValues) {
        searchParams.set(hubSpotInternalName, formValues[source])
      }
    }
  )

  return (
    <ExternalLink
      id={id}
      url={`${hubSpotFormUrl.url}?${searchParams.toString()}`}
      ariaLabel={ariaLabel}
      tabIndex={tabIndex}
      buttonVariant={buttonVariant}
      buttonSize={buttonSize}
      buttonColor={buttonColor}
      buttonStyles={buttonStyles}
      isButton={isButton}
      fontCase={fontCase}
      className={className}
    >
      {children}
    </ExternalLink>
  )
}

const Link = ({
  link,
  children,
  tabIndex,
  onClick,
  className,
  showCollectionCount,
  buttonVariant,
  buttonSize,
  buttonColor,
  'aria-label': ariaLabel,
}: LinkProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { getProductCount } = useContext(SiteContext)
  const router = useRouter()
  const logoutUser = useLogoutUser()

  const pageType = link.page && (link.page.type as PageType)
  const pageSlug = link.page?.slug
  const isCollection = !!pageType && isRouteCollection(pageType)
  const collectionSlug = isCollection && pageSlug ? pageSlug : 'all'
  const collectionCount = getProductCount(collectionSlug)

  const { id, url, hubSpotFormUrl, isButton, fontCase, buttonStyles } = link

  // HubSpot form link
  if (hubSpotFormUrl) {
    return (
      <HubSpotLink
        id={id}
        hubSpotFormUrl={hubSpotFormUrl}
        ariaLabel={ariaLabel}
        tabIndex={tabIndex}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyles={buttonStyles}
        isButton={isButton}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </HubSpotLink>
    )
  }

  // External link
  if (url) {
    return (
      <ExternalLink
        id={id}
        url={url}
        ariaLabel={ariaLabel}
        tabIndex={tabIndex}
        buttonVariant={buttonVariant}
        buttonSize={buttonSize}
        buttonColor={buttonColor}
        buttonStyles={buttonStyles}
        isButton={isButton}
        fontCase={fontCase}
        className={className}
      >
        {children}
      </ExternalLink>
    )
  }

  // Internal Page
  if (!pageType) {
    return null
  }

  const pageUrl = getPageUrl(pageType, pageSlug)

  const getClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (pageType === PageType.LOGOUT_PAGE) {
      event.preventDefault()
      logoutUser(() => {
        router.push(getPageUrl(PageType.LOGIN_PAGE))
      })
    }

    onClick && onClick()
  }

  const beforeInputHandler = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (pageType === PageType.LOGOUT_PAGE) {
      event.preventDefault()
      logoutUser(() => {
        router.push(getPageUrl(PageType.LOGIN_PAGE))
      })
    }

    onClick && onClick()
  }

  return (
    <NextLink href={pageUrl}>
      <Button
        id={id}
        role="link"
        tabIndex={tabIndex}
        onClick={getClickHandler}
        onBeforeInput={beforeInputHandler}
        aria-label={ariaLabel}
        variant={buttonVariant ?? getButtonVariant(buttonStyles?.variant)}
        size={buttonSize ?? getButtonSize(buttonStyles?.size)}
        color={buttonColor ?? getButtonColor(buttonStyles?.color)}
        icon={buttonStyles?.icon}
        iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
        className={cx(
          { btn: isButton, 'w-full': buttonStyles?.isFullWidth },
          fontCase ?? '',
          className
        )}
      >
        {children}

        {showCollectionCount && isCollection && (
          <span
            aria-hidden="true"
            className="inline-block relative ml-2 leading-none align-super text-[.5em]"
          >
            {collectionCount}
          </span>
        )}
      </Button>
    </NextLink>
  )
}

export default Link
