import { getRandomString } from './helpers'

export const bookDemoEventName = 'Book a demo'
export const contactEventName = 'Contact'
export const newsletterSignUpEventName = 'Newsletter sign up'
export const pageLoadEventName = 'Page load'
export const pageNavigationEventName = 'Page navigation'
export const pageViewEventName = 'Pageview'

/**
 * Triggers pageview events in GTM and GA.
 */
export const triggerPageviewEvent = () => {
  // Push Google Tag Manager data layer event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'Pageview',
    eventId: getRandomString(),
    pagePath: window.location.pathname,
    pageTitle: document.title,
  })

  // Push Google Analytics event
  if (window['ga']) {
    window.ga('set', {
      location: location.href,
      page: location.pathname,
      title: document.title,
    })
    window.ga('send', 'pageview')
  }
}

/**
 * Triggers an event in Google Tag Manager.
 */
export const triggerGoogleTagManagerEvent = (
  event: string,
  extraAttributes?: Record<string, any>
) => {
  let dataLayerEvent = {
    event,
    eventId: getRandomString(),
  }

  if (extraAttributes) {
    dataLayerEvent = {
      ...dataLayerEvent,
      ...extraAttributes,
    }
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayerEvent)
}
