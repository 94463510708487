import cx from 'classnames'

type ColorClassMap = Record<string, string>

const colorClasses: ColorClassMap = {
  white: 'text-white',
  black: 'text-black',
}

interface ColoredTextProps {
  mark: Record<string, string>
  children: string[]
}

const ColoredText = ({ mark, children }: ColoredTextProps) => {
  return (
    <span
      className={cx(colorClasses[mark.color], { 'opacity-50': mark.faded })}
    >
      {children[0]}
    </span>
  )
}

ColoredText.displayName = 'ColoredText'

export default ColoredText
