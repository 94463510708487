import { createClient, type SanityClient } from '@sanity/client'

export const SANITY_API_VERSION = 'v2023-08-01'

/**
 * Creates a Sanity client with or without preview mode enabled.
 */
export const getSanityClient = (draftToken?: string): SanityClient => {
  const client = createClient({
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    dataset: process.env.NEXT_PUBLIC_SANITY_PROJECT_DATASET,
    apiVersion: SANITY_API_VERSION,
    useCdn: process.env.NODE_ENV === 'production',
  })

  if (draftToken) {
    return client.withConfig({
      token: draftToken,
      useCdn: false,
      ignoreBrowserTokenWarning: true,
      perspective: 'previewDrafts',
    })
  }

  return client
}

/**
 * Creates a Sanity client for pages.
 */
export const getPageSanityClient = (draftMode: boolean = false) => {
  const draftToken = !!draftMode ? process.env.SANITY_READ_API_TOKEN : ''

  if (!!draftMode && !draftToken) {
    throw new Error(
      'Draft mode is active, but SANITY_READ_API_TOKEN is not set in environment variables'
    )
  }

  return [getSanityClient(draftToken), !!draftMode, draftToken] as const
}

/**
 * Fetches data from Sanity using a GROQ query.
 */
export const fetchQuery = async <T, P = Record<string, never>>(
  sanityClient: SanityClient,
  query: string,
  params?: P
) => {
  // Remove multiple spaces and newlines in a row
  let parsedQuery = query.replace(/\s{2,}/g, ' ')
  // Trim leading and trailing spaces and newlines
  parsedQuery = parsedQuery.replace(/^\s+|\s+$/g, '')
  // Remove spaces before and after special characters
  parsedQuery = parsedQuery
    .replaceAll(' == ', '==')
    .replaceAll(' != ', '!=')
    .replaceAll(' => ', '=>')
    .replaceAll(' && ', '&&')
    .replaceAll(' || ', '||')
    .replaceAll(' | ', '|')
    .replaceAll(') [', ')[')
    .replaceAll('] {', ']{')
    .replaceAll(' {', '{')
    .replaceAll('{ ', '{')
    .replaceAll(' }', '}')
    .replaceAll('[ ', '[')
    .replaceAll(' ]', ']')
    .replaceAll(': ', ':')
    .replaceAll(', ', ',')
    .replaceAll(',}', '}')

  return sanityClient.fetch<T>(query, params ?? {})
}
