import cx from 'classnames'

import { SanityContentHorizontalRule } from '@data/sanity/queries/types/content'

interface HorizontalRuleProps {
  node: SanityContentHorizontalRule
}

const HorizontalRule = ({ node }: HorizontalRuleProps) => {
  return (
    <hr
      className={cx(`bg-${node.color ?? 'current'}`, {
        'opacity-25': node.faded,
      })}
    />
  )
}

HorizontalRule.displayName = 'HorizontalRule'

export default HorizontalRule
