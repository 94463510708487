import { SanityContentQuote } from '@data/sanity/queries/types/content'

import Quote from '@components/quote'

interface QuoteCardProps {
  node: Pick<
    SanityContentQuote,
    'author' | 'authorImage' | 'alignment' | 'size' | 'accentDot'
  > & {
    text: string
  }
}

type QuoteSize = 'small' | 'large'

const QuoteCard = ({ node }: QuoteCardProps) => {
  const { text, author, authorImage, alignment, size, accentDot } = node

  return (
    <Quote
      author={author}
      authorImage={authorImage}
      alignment={alignment}
      size={size as QuoteSize}
      accentDot={accentDot}
    >
      {text}
    </Quote>
  )
}

QuoteCard.displayName = 'QuoteCard'

export default QuoteCard
