import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import {
  compareNumbers,
  enumToObject,
  filterDuplicates,
  useLocalStorageState,
} from './helpers'
import { getPageUrl, PageType } from './routes'
import { SiteContext } from './site'

export enum Locale {
  ENGLISH = 'en',
}

export const defaultLocale = Locale.ENGLISH

export type LocaleValues<T> = {
  [P in Locale]: T
}

export const localeNames: LocaleValues<string> = {
  [Locale.ENGLISH]: 'English',
}

interface LanguageContextProps {
  locale: Locale
  publicLocales: Locale[]
  changeLanguage: (newLocale: Locale) => void
}

const initialContext: LanguageContextProps = {
  locale: defaultLocale,
  publicLocales: [],
  changeLanguage: () => null,
}

export const LanguageContext =
  createContext<LanguageContextProps>(initialContext)

interface LanguageContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  children: ReactNode
}

export const LanguageContextProvider = ({
  locale,
  site,
  children,
}: LanguageContextProviderProps) => {
  const router = useRouter()
  const { toggleMegaNavigation, toggleMobileMenu } = useContext(SiteContext)

  // Handle language change
  const changeLanguage = useCallback(
    (newLocale: Locale) => {
      toggleMegaNavigation(false)
      toggleMobileMenu(false)

      if (locale !== newLocale) {
        const homeUrl = getPageUrl(PageType.HOME_PAGE)
        router.push(homeUrl, homeUrl, { locale: newLocale })
      }
    },
    [locale, router, toggleMegaNavigation, toggleMobileMenu]
  )

  return (
    <LanguageContext.Provider
      value={{
        locale,
        publicLocales: site.publicLocales as Locale[],
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

/**
 * Gets all locales.
 */
export const getAllLocales = () =>
  Object.entries(enumToObject(Locale)).map((value) => value[1] as Locale)

/*  ------------------------------ */
/*  Environment variables
/*  ------------------------------ */

type EnvironmentVariableName =
  | 'NEXT_PUBLIC_SANITY_PROJECT_ID'
  | 'NEXT_PUBLIC_SANITY_PROJECT_DATASET'
  | 'SANITY_READ_API_TOKEN'
  | 'SANITY_API_TOKEN'
  | 'SANITY_PREVIEW_TOKEN'
  | 'NEXT_PUBLIC_SHOPIFY_STORE_ID'
  | 'NEXT_PUBLIC_SHOPIFY_API_TOKEN'
  | 'SHOPIFY_API_PASSWORD'
  | 'SHOPIFY_WEBHOOK_INTEGRITY'
  | 'RECHARGE_API_TOKEN'
  | 'REPORT_URI_SUBDOMAIN'
  | 'MAILCHIMP_API_KEY'
  | 'MAILCHIMP_SERVER'
  | 'FACEBOOK_CONVERSIONS_API_TOKEN'

type EnvironmentVariableMap = Record<
  Locale | 'default',
  Record<EnvironmentVariableName, string | undefined>
>

const environmentVariables: EnvironmentVariableMap = {
  default: {
    NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    NEXT_PUBLIC_SANITY_PROJECT_DATASET:
      process.env.NEXT_PUBLIC_SANITY_PROJECT_DATASET,
    SANITY_READ_API_TOKEN: process.env.SANITY_READ_API_TOKEN,
    SANITY_API_TOKEN: process.env.SANITY_API_TOKEN,
    SANITY_PREVIEW_TOKEN: process.env.SANITY_PREVIEW_TOKEN,
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY,
    RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN,
    MAILCHIMP_API_KEY: process.env.MAILCHIMP_API_KEY,
    MAILCHIMP_SERVER: process.env.MAILCHIMP_SERVER,
    FACEBOOK_CONVERSIONS_API_TOKEN: process.env.FACEBOOK_CONVERSIONS_API_TOKEN,
  },
  en: {
    NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID_EN,
    NEXT_PUBLIC_SANITY_PROJECT_DATASET:
      process.env.NEXT_PUBLIC_SANITY_PROJECT_DATASET_EN,
    SANITY_READ_API_TOKEN: process.env.SANITY_READ_API_TOKEN_EN,
    SANITY_API_TOKEN: process.env.SANITY_API_TOKEN_EN,
    SANITY_PREVIEW_TOKEN: process.env.SANITY_PREVIEW_TOKEN_EN,
    NEXT_PUBLIC_SHOPIFY_STORE_ID: process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID_EN,
    NEXT_PUBLIC_SHOPIFY_API_TOKEN: process.env.NEXT_PUBLIC_SHOPIFY_API_TOKEN_EN,
    SHOPIFY_API_PASSWORD: process.env.SHOPIFY_API_PASSWORD_EN,
    SHOPIFY_WEBHOOK_INTEGRITY: process.env.SHOPIFY_WEBHOOK_INTEGRITY_EN,
    RECHARGE_API_TOKEN: process.env.RECHARGE_API_TOKEN_EN,
    REPORT_URI_SUBDOMAIN: process.env.REPORT_URI_SUBDOMAIN_EN,
    MAILCHIMP_API_KEY: process.env.MAILCHIMP_API_KEY_EN,
    MAILCHIMP_SERVER: process.env.MAILCHIMP_SERVER_EN,
    FACEBOOK_CONVERSIONS_API_TOKEN:
      process.env.FACEBOOK_CONVERSIONS_API_TOKEN_EN,
  },
}

/**
 * Gets environment variable for specific locale.
 */
export const getLocaleVariable = (
  locale: Locale,
  key: EnvironmentVariableName
) => environmentVariables[locale]?.[key] ?? environmentVariables.default[key]

/**
 * Gets all locales that use a Shopify store.
 */
export const getShopifyStoreLocales = (shopifyStoreId: string) => {
  const locales = getAllLocales()

  return locales.filter(
    (locale) =>
      getLocaleVariable(locale, 'NEXT_PUBLIC_SHOPIFY_STORE_ID') ===
      shopifyStoreId
  )
}

/*  ------------------------------ */
/*  Locale detection
/*  ------------------------------ */

/**
 * Returns user's locales as IETF language tags, based on all available sources.
 * Source: https://github.com/wojtekmaj/get-user-locale
 */
const getBrowserLocales = () => {
  if (typeof window === 'undefined') {
    return [defaultLocale]
  }

  let locales: string[] = []

  if (window.navigator.languages) {
    locales = locales.concat(window.navigator.languages)
  }

  if (window.navigator.language) {
    locales.push(window.navigator.language)
  }

  if (window.navigator.userLanguage) {
    locales.push(window.navigator.userLanguage)
  }

  if (window.navigator.browserLanguage) {
    locales.push(window.navigator.browserLanguage)
  }

  if (window.navigator.systemLanguage) {
    locales.push(window.navigator.systemLanguage)
  }

  locales.push(defaultLocale)

  return locales
    .filter((locale, index, array) => filterDuplicates(locale, index, array))
    .map((locale) => getNormalizedLocale(locale))
}

/**
 * Gets normalized locale by fixing capitalization.
 */
export const getNormalizedLocale = (locale: string) => {
  if (
    !locale ||
    locale.indexOf('-') === -1 ||
    locale.toLowerCase() !== locale
  ) {
    return locale
  }

  const localeParts = locale.split('-')

  return `${localeParts[0]}-${localeParts[1].toUpperCase()}`
}

/**
 * Finds the best matching site locale from all site and browser locales.
 */
export const getMatchingSiteLocale = (
  siteLocales: Locale[],
  browserLocales: string[],
  defaultSiteLocale: Locale
) => {
  const matches = siteLocales
    .map((siteLocale) => {
      const exactIndex = browserLocales.indexOf(siteLocale)
      const partialIndex = browserLocales
        .map((locale) => locale.split('-')[0])
        .indexOf(siteLocale.split('-')[0])

      return {
        locale: siteLocale,
        index: exactIndex > -1 ? exactIndex : partialIndex,
      }
    })
    .filter(({ index }) => index > -1)
    .sort((match1, match2) => compareNumbers(match1.index, match2.index))

  if (matches.length > 0) {
    return matches[0].locale
  }

  return defaultSiteLocale
}

/**
 * Visitor's preferred locale detection hook.
 * Returns preferred locale (if it doesn't match site's locale) and a method to confirm current locale.
 */
export const usePreferredLocale = (locale: Locale) => {
  const [preferredLocale, setPreferredLocale] = useState<Locale | null>(null)
  const [browserLocales, setBrowserLocales] = useState<string[]>([])
  const [isLocaleConfirmed, setIsLocaleConfirmed] =
    useLocalStorageState<boolean>('is_locale_confirmed', false)

  // Detect browser locales
  useEffect(() => {
    if (isLocaleConfirmed) {
      return
    }

    const newBrowserLocales = getBrowserLocales()

    setBrowserLocales(newBrowserLocales)
  }, [isLocaleConfirmed])

  // Find best matching site locale based on browser locales
  useEffect(() => {
    if (isLocaleConfirmed || browserLocales.length === 0) {
      setPreferredLocale(null)

      return
    }

    const siteLocales = getAllLocales()
    const matchingSiteLocale = getMatchingSiteLocale(
      siteLocales,
      browserLocales,
      defaultLocale
    )

    // Save preferred locale if it's not already being used
    setPreferredLocale(
      matchingSiteLocale !== locale ? matchingSiteLocale : null
    )
  }, [browserLocales, isLocaleConfirmed, locale])

  const confirmLocale = useCallback(() => {
    if (!isLocaleConfirmed) {
      setIsLocaleConfirmed(true)
    }
  }, [isLocaleConfirmed, setIsLocaleConfirmed])

  return [preferredLocale, confirmLocale] as const
}
